import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Layout from "../components/Layout";

const serviceKey = process.env.REACT_APP_SERVICE;
const publicKey = process.env.REACT_APP_PUBLIC;
const templateKey = process.env.REACT_APP_TEMPLATE;

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(`${serviceKey}`, `${templateKey}`, form.current, `${publicKey}`)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <Layout>
      <div className="mt-20">
        <div className="max-h-screen">
          <div className="flex justify-center items-center">
            <div className="text-2xl font-semibold w-3/4 md:w-full flex flex-col p-5 m-10 md:m-5 shadow-2xl bg-accent rounded">
              <h1>Contact me..</h1>
              <form
                ref={form}
                onSubmit={sendEmail}
                className="text-lg md:text-base sm:text-sm"
              >
                <input
                  name="sender_name"
                  required
                  type="text"
                  placeholder="Name"
                  className="border-2  rounded p-1 mt-5 border-slate-300 w-full"
                />
                <input
                  name="sender_email"
                  required
                  type="email"
                  placeholder="E-mail"
                  className="border-2 rounded p-1 mt-5 border-slate-300 w-full"
                />
                <input
                  name="email_subject"
                  type="text"
                  placeholder="Subject"
                  className="border-2 rounded p-1 mt-5 border-slate-300 w-full"
                />
                <textarea
                  name="main_message"
                  required
                  type="text"
                  placeholder="Query"
                  className="border-2 rounded p-1 mt-5 border-slate-300 w-full"
                />
                <button type="submit" className="btn btn-secondary my-3">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
