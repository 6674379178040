import React from "react";
import Layout from "../components/Layout";
import projData from "../resources/project";

function Projects() {
  return (
    <Layout>
      <div className=" mt-20">
        <div className="max-h-screen">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 gap-10 p-10">
            {projData.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex flex-col justify-center items-center relative "
                >
                  <img
                    className="object-fill border-4 border-info-content rounded-tr-3xl rounded-bl-3xl"
                    src={item.img}
                    alt=""
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-info-content bg-black opacity-0 hover:opacity-80">
                    <h1 className=" text-3xl font-semibold">{item.title}</h1>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <button className="btn btn-secondary btn-outline m-2">
                        DEMO
                      </button>
                    </a>
                  </div>
                </div>
                // </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Projects;
