import Header from "./Header";
import Footer from "./Footer";
import React, { useEffect } from "react";

function Layout({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Header />
      <div className="content flex  flex-col flex-grow bg-accent-focus">
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
