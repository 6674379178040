import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <div className="navbar bg-neutral-focus p-3 text-center justify-center flex flex-col">
      <p className=" text-xl text-fuchsia-200 font-kanit font-bold">
        Designed and Developed By
      </p>
      <div className="text-info-focus justify-between w-48 border-2 border-success-content p-6 my-2">
        <a href="https://github.com/blsgrn" target="_blank" rel="noreferrer">
          <FaGithub className="hover:text-blue-300 text-yellow-200" size={40} />
        </a>
        <a
          href="https://www.linkedin.com/in/subramaniam-b-8812b8245/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin
            className="hover:text-blue-300 text-yellow-200"
            size={40}
          />
        </a>
      </div>
      <p className=" whitespace-nowrap text-2xl md:text-xl sm:text-lg font-domine text-primary">
        S.Segaran
      </p>
    </div>
  );
}

export default Footer;
