import image1 from "./images/weatherapp.png";
import image2 from "./images/githubfinder.png";
import shortid from "shortid";

const projData = [
  {
    title: "Weather App",
    id: shortid.generate(),
    img: image1,
    desc: " A responsive (to different screen sizes) website that fetches real time weather information from 8 cities",
    link: "https://segaranweatherapp.netlify.app/",
  },
  {
    title: "Github Finder",
    id: shortid.generate(),
    img: image2,
    desc: "A website to search github users and click on profile to see details",
    link: "https://github-finder34.netlify.app/",
  },
];

export default projData;
